import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class FlagshipGoalsWizardProcessModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.flagship.flagship-goals.flagship_goals_wizard_process';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = () => {
    // this view has no endpoint, it's just the step to the goals dashboard
    setTimeout(() => {
      window.location.href = '/goals-dashboard';
    }, 2000);
  }
}
