

























import { Component, Vue } from 'vue-property-decorator';

import FlagshipGoalsWizardProcessModel
  from '@/vue-app/view-models/components/flagship/flagship-goals/flagship-goals-wizard-process-model';

@Component({ name: 'FlagshipGoalsWizardProcess' })
export default class FlagshipGoalsWizardProcess extends Vue {
  flagship_wizard_process_model = Vue.observable(new FlagshipGoalsWizardProcessModel());

  created() {
    this.flagship_wizard_process_model.initialize();
  }
}
